
var rts_background = document.getElementById("houses_background");
var fps_background = document.getElementById("mountains_background");
var tkn_background = document.getElementById("artstyle");

document.addEventListener('scroll', function (e) {
    //FPS Background
    currScroll = fps_background.getBoundingClientRect().top;
    currentPositionX = getComputedStyle(fps_background).backgroundPositionX;
    newPositionY = -currScroll * -0.1;
    fps_background.style.backgroundPosition = currentPositionX + " " + newPositionY + "px";

    //RTS Background
    currScroll = rts_background.getBoundingClientRect().top;
    currentPositionX = getComputedStyle(rts_background).backgroundPositionX;
    newPositionY = -currScroll * -0.1;
    rts_background.style.backgroundPosition = currentPositionX + " " + newPositionY + "px";

    //artstyle Backgorund
    currScroll = tkn_background.getBoundingClientRect().top;
    currentPositionX = getComputedStyle(tkn_background).backgroundPositionX;
    newPositionY = -currScroll * -0.1;
    tkn_background.style.backgroundPosition = currentPositionX + " " + newPositionY + "px";
    
});